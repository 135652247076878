import { EDisclaimerKeys } from "@/utilities/enums/Disclaimer";
import { E_ENQUIRY_LOG_TYPE } from "@/utilities/enums/EnquiryLogTypes";
import { E_LEAD_SCORE_TYPE } from "@/utilities/enums/LeadScoreTypes";
import { EProjectCategoryTypes } from "@/utilities/enums/ProjectCategory";
import { ESiteContentType } from "@/utilities/enums/SiteContent";
import { ESocialLinks } from "@/utilities/enums/Social";
import { EUnitTransactionStatus } from "@/utilities/enums/UnitTransaction";
import { EUserReferenceType } from "@/utilities/enums/UserPreference";

export const CART_KEY = "isomorphic-cart";
export const POS_CART_KEY = "isomorphic-pos-cart";
export const DUMMY_ID = "FC6723757651DB74";
export const CHECKOUT = "isomorphic-checkout";
export const CURRENCY_CODE = "USD";
export const LOCALE = "en";
export const LANGUAGE_KEY = "lang";
export const PROJECT_MODAL_SHOWN_KEY = "projectModalShown";
export const AGENCY_PROJECT_MODAL_SHOWN_KEY = "agencyProjectModalShown";
export const CURRENCY_OPTIONS = {
  formation: "en-US",
  fractions: 2,
};

export const ROW_PER_PAGE_OPTIONS = [
  {
    value: 5,
    name: "5",
  },
  {
    value: 10,
    name: "10",
  },
  {
    value: 15,
    name: "15",
  },
  {
    value: 20,
    name: "20",
  },
];

export const ROLES = {
  Administrator: "Administrator",
  Manager: "Manager",
  Sales: "Sales",
  Support: "Support",
  Developer: "Developer",
  HRD: "HR Department",
  RestrictedUser: "Restricted User",
  Customer: "Customer",
} as const;

export const WEB_DOMAIN =
  (import.meta.env.VITE_BASE_WEB_URL as string) ||
  "http://ec2-18-141-58-236.ap-southeast-1.compute.amazonaws.com";

export const SOCIAL_TYPES: Record<ESocialLinks, { label: string }> = {
  [ESocialLinks.FACEBOOK]: { label: "Facebook" },
  [ESocialLinks.INSTAGRAM]: { label: "Instagram" },
  [ESocialLinks.TIKTOK]: { label: "Tiktok" },
};

export const CDN_URL = import.meta.env.VITE_ASSET_BASE_URL as string;

export const DATE_FORMAT = {
  DATE: "MMMM DD, YYYY",
  DATE_TIME: "MMMM DD, YYYY hh:mm A",
  DATE_TIME_SPLICE: "MMMM DD, YYYY. hh:mm A",
  TIME: "hh:mm A",
};

export const PROJECT_CATEGORY_TYPES: Record<
  EProjectCategoryTypes,
  { label: string }
> = {
  [EProjectCategoryTypes.RESIDENTIAL]: { label: "Residential" },
  [EProjectCategoryTypes.COMMERCIAL]: { label: "Commercial" },
};

export const UNIT_TRANSACTION_STATUS: Record<
  EUnitTransactionStatus,
  { label: string }
> = {
  [EUnitTransactionStatus.AVAILABLE]: { label: "Available" },
  [EUnitTransactionStatus.SOLD]: { label: "Sold" },
};

export const USER_PREFERENCE_TYPE: Record<
  EUserReferenceType,
  { label: string }
> = {
  [EUserReferenceType.LOCATION]: { label: "Location" },
  [EUserReferenceType.RANGE]: { label: "Range" },
  [EUserReferenceType.UNIT_TYPE]: { label: "Unit Type" },
};

export const SITE_CONTENT_TYPES: Record<ESiteContentType, { label: string }> = {
  [ESiteContentType.ABOUT_US]: { label: "About us" },
  [ESiteContentType.CCR]: { label: "CCR" },
  [ESiteContentType.CONTACT_US]: { label: "Contact us" },
  [ESiteContentType.OCR]: { label: "OCR" },
  [ESiteContentType.PARTNER_US]: { label: "Partner us" },
  [ESiteContentType.PRIVACT_POLICY]: { label: "Privacy policy" },
  [ESiteContentType.RCR]: { label: "RCR" },
};

const RAW_SUFFIX_DOMAIN = import.meta.env.VITE_SUFFIX_DOMAIN as string;

export const SUFFIX_DOMAIN = RAW_SUFFIX_DOMAIN?.startsWith(".")
  ? RAW_SUFFIX_DOMAIN
  : `.${RAW_SUFFIX_DOMAIN}`;

export const DISCLAIMERS: Record<EDisclaimerKeys, { label: string }> = {
  [EDisclaimerKeys.Disclaimer]: { label: "Project Disclaimer" },
  [EDisclaimerKeys.FooterDisclaimer]: { label: "Footer Disclaimer" },
  [EDisclaimerKeys.FormNotice]: { label: "Form Notice" },
};

export const LEAD_SCORE_COLORS = {
  AGENT_DUPLICATE: "rgba(146, 146, 146, 1)", // Grey
  COLD: "rgba(238, 0, 0, 1)", // Red
  WARM: "rgba(245, 166, 35, 1)", // Orange
  HOT: "rgba(17, 168, 73, 1)", // Green
};

export const LEAD_SCORE_RIZZUI_COLORS = {
  AGENT_DUPLICATE: "primary", // Grey
  COLD: "danger", // Red
  WARM: "warning", // Orange
  HOT: "success", // Green
};

export const LEAD_FULL_SCORE = 80;

export const ENQUIRY_LOG_TYPES: Record<E_ENQUIRY_LOG_TYPE, { label: string }> =
  {
    [E_ENQUIRY_LOG_TYPE.APPOINTMENT_DATE]: { label: "Appointment Date" },
    [E_ENQUIRY_LOG_TYPE.INTERESTED]: { label: "Interested in" },
    [E_ENQUIRY_LOG_TYPE.PROJECT_VIEW_COUNT]: { label: "Project View Count" },
    [E_ENQUIRY_LOG_TYPE.PROJECT_VIEW_DURATION]: {
      label: "Project View Duration",
    },
    [E_ENQUIRY_LOG_TYPE.RED_FLAG]: { label: "Red Flag" },
    [E_ENQUIRY_LOG_TYPE.UNIT_TYPE]: { label: "Unit types selected" },
  };

export const LEAD_SCORE_TYPES: Record<
  E_LEAD_SCORE_TYPE,
  { label: string; color: string; rizzuiColor: string }
> = {
  [E_LEAD_SCORE_TYPE.AGENT_DUPLICATE]: {
    label: "Agent/Duplicate",
    color: LEAD_SCORE_COLORS.AGENT_DUPLICATE,
    rizzuiColor: LEAD_SCORE_RIZZUI_COLORS.AGENT_DUPLICATE,
  },
  [E_LEAD_SCORE_TYPE.COLD]: {
    label: "Cold",
    color: LEAD_SCORE_COLORS.COLD,
    rizzuiColor: LEAD_SCORE_RIZZUI_COLORS.COLD,
  },
  [E_LEAD_SCORE_TYPE.HOT]: {
    label: "Hot",
    color: LEAD_SCORE_COLORS.HOT,
    rizzuiColor: LEAD_SCORE_RIZZUI_COLORS.HOT,
  },
  [E_LEAD_SCORE_TYPE.WARM]: {
    label: "Warm",
    color: LEAD_SCORE_COLORS.WARM,
    rizzuiColor: LEAD_SCORE_RIZZUI_COLORS.WARM,
  },
};

export const COUNTRY_SG_CODE = "+65";

export const APP_SUPPORT_DOMAIN =
  import.meta.env.VITE_APP_SUPPORT_DOMAIN ||
  "app-development.staging.project.sg";
